<template>
    <div class="workbench">
        <div class="title_top">
            <span class="iconfont icon-gongsi3"></span>
            账号信息
        </div>
        <div class="text_box">
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(1)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>1</span>
                </div>
                <p><span>公司抬头：</span>浙江伽搜科技有限公司</p>
                <p><span>税号：</span>91330106067874175U</p>
                <p>
                    <span>地址：</span
                    >杭州市西湖区文三路553号浙江中小企业大厦1703室
                </p>
                <p><span>电话：</span>0571-56572028</p>
                <p><span>开户行：</span>中国建设银行杭州华星支行</p>
                <p><span>账号： </span>33001616783059959595</p>
                <p><span>开票类型： </span>电子普票、纸质专票</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(2)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>2</span>
                </div>
                <p><span>公司抬头：</span>浙江邮点好信息技术有限公司</p>
                <p><span>税号：</span>91330205MABRC7MN48</p>
                <p>
                    <span>地址：</span
                    >浙江省宁波市江北区长兴路689弄21号10幢112室
                </p>
                <p><span>电话：</span>057427890559</p>
                <p><span>开户行：</span>中国光大银行股份有限公司宁波中山支行</p>
                <p><span>账号： </span>77700188000108667</p>
                <p><span>开票类型： </span>- -</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(3)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>3</span>
                </div>
                <p><span>公司抬头：</span>杭州钱邮科技有限公司</p>
                <p><span>税号：</span>91330109MA2GLFB512</p>
                <p>
                    <span>地址：</span
                    >浙江省杭州市西湖区花蒋天街商业中心3号楼717室
                </p>
                <p><span>电话：</span>0571-56572028</p>
                <p><span>开户行：</span>招商银行杭州高新支行</p>
                <p><span>账号： </span>571913337610902</p>
                <p><span>开票类型： </span>电子普票、纸质专票</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(4)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>4</span>
                </div>
                <p><span>公司抬头：</span>苏州企邮会信息技术有限公司</p>
                <p><span>税号：</span>91320509MA20WH431U</p>
                <p><span>地址：</span>吴江经济技术开发区兴吴路1189号</p>
                <p><span>电话：</span>0512-89160209</p>
                <p><span>开户行：</span>招商银行股份有限公司吴江支行</p>
                <p><span>账号： </span>512909573510901</p>
                <p><span>开票类型： </span>电子普票、纸质专票</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(5)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>5</span>
                </div>
                <p><span>公司抬头：</span>杭州非线性科技有限公司</p>
                <p><span>税号：</span>91330106MA2KDLAJ6K</p>
                <p>
                    <span>地址：</span
                    >浙江省杭州市西湖区紫金启真大厦3号楼501室-8
                </p>
                <p><span>电话：</span>0571-56572028</p>
                <p><span>开户行：</span>招商银行杭州高新支行</p>
                <p><span>账号： </span>571915882010202</p>
                <p><span>开票类型： </span>电子专票、纸质专票</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(6)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>6</span>
                </div>
                <p><span>公司抬头：</span>杭州辰宿科技有限公司</p>
                <p><span>税号：</span>91330106MA2KK63U9P</p>
                <p>
                    <span>地址：</span
                    >浙江省杭州市西湖区三墩镇三墩街1号世创大厦1403室
                </p>
                <p><span>电话：</span>0571-56572028</p>
                <p><span>开户行：</span>招商银行杭州高新支行</p>
                <p><span>账号： </span>571916562210902</p>
                <p><span>开票类型： </span>电子普票、电子专票</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(7)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>7</span>
                </div>
                <p><span>公司抬头：</span>上海瑞外纽信息技术有限公司</p>
                <p><span>税号：</span>91310120MA7GHCE921</p>
                <p><span>地址：</span>上海市奉贤区金海公路4808弄30号408室</p>
                <p><span>电话：</span>0571-56572028</p>
                <p><span>开户行：</span>交通银行上海漕河泾支行</p>
                <p><span>账号： </span>310066632013005339717</p>
                <p><span>开票类型： </span>电子普票</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(8)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>8</span>
                </div>
                <p><span>公司抬头：</span>成都预明科技有限公司</p>
                <p><span>税号：</span>91510100MA7EE35H8R</p>
                <p>
                    <span>地址：</span
                    >中国（四川）自由贸易试验区成都高新区天府大道中段1388号1栋8层806号
                </p>
                <p><span>电话：</span>0571-56572028</p>
                <p><span>开户行：</span>贵阳银行成都分行</p>
                <p><span>账号： </span>21010123670005011</p>
                <p><span>开票类型： </span>纸质普票、电子专票</p>
            </div>
            <div class="content">
                <div class="index">
                    <span
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(9)"
                            class="iconfont icon-fuzhi1"
                        ></el-button
                    ></span>
                    <span>9</span>
                </div>
                <p><span>公司抬头：</span>杭州伽湾信息技术有限公司</p>
                <p><span>税号：</span>91330106MA27YC4HXJ</p>
                <p>
                    <span>地址：</span>
                    浙江省杭州市西湖区三墩镇石祥西路859号紫金启真大厦3号楼501-11室
                </p>
                <p><span>电话：</span>0571-56572028</p>
                <p><span>开户行：</span>中国建设银行杭州华星支行</p>
                <p><span>账号： </span>33050161678300000112</p>
                <p><span>开票类型： </span>电子普票、纸质专票</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    methods: {
        onCopy(i) {
            let url = '';
            if (i == 1) {
                url = `公司抬头：浙江伽搜科技有限公司
税号：91330106067874175U
地址：杭州市西湖区文三路553号浙江中小企业大厦1703室
电话：0571-56572028
开户行：中国建设银行杭州华星支行
账号：33001616783059959595
开票类型：电子普票、纸质专票`;
            }
            if (i == 2) {
                url = `公司抬头：浙江邮点好信息技术有限公司
税号：91330205MABRC7MN48
地址：浙江省宁波市江北区长兴路689弄21号10幢112室
电话：057427890559
开户行：中国光大银行股份有限公司宁波中山支行
账号：77700188000108667
开票类型：- -`;
            }
            if (i == 3) {
                url = `公司抬头：杭州钱邮科技有限公司
税号：91330109MA2GLFB512
地址：浙江省杭州市西湖区花蒋天街商业中心3号楼717室
电话：0571-56572028
开户行：招商银行杭州高新支行
账号：571913337610902
开票类型：电子普票、纸质专票`;
            }
            if (i == 4) {
                url = `公司抬头：苏州企邮会信息技术有限公司
税号：91320509MA20WH431U
地址：吴江经济技术开发区兴吴路1189号
电话：0512-89160209
开户行：招商银行股份有限公司吴江支行
账号：512909573510901
开票类型：电子普票、纸质专票`;
            }
            if (i == 5) {
                url = `公司抬头：杭州非线性科技有限公司
税号：91330106MA2KDLAJ6K
地址：浙江省杭州市西湖区紫金启真大厦3号楼501室-8
电话：0571-56572028
开户行：招商银行杭州高新支行
账号：571915882010202
开票类型：电子专票、纸质专票`;
            }
            if (i == 6) {
                url = `公司抬头：杭州辰宿科技有限公司
税号：91330106MA2KK63U9P
地址：浙江省杭州市西湖区三墩镇三墩街1号世创大厦1403室
电话：0571-56572028
开户行：招商银行杭州高新支行
账号：571916562210902
开票类型：电子普票、电子专票`;
            }
            if (i == 7) {
                url = `公司抬头：上海瑞外纽信息技术有限公司
税号：91310120MA7GHCE921
地址：上海市奉贤区金海公路4808弄30号408室
电话：0571-56572028
开户行：交通银行上海漕河泾支行
账号：310066632013005339717
开票类型：电子普票`;
            }
            if (i == 8) {
                url = `公司抬头：成都预明科技有限公司
税号：</span>91510100MA7EE35H8R
地址：中国（四川）自由贸易试验区成都高新区天府大道中段1388号1栋8层806号
电话：0571-56572028
开户行：贵阳银行成都分行
账号：21010123670005011
开票类型：纸质普票、电子专票`;
            }
            if (i == 9) {
                url = `公司抬头：杭州伽湾信息技术有限公司
税号：91330106MA27YC4HXJ
地址：浙江省杭州市西湖区三墩镇石祥西路859号紫金启真大厦3号楼501-11室
电话：0571-56572028
开户行：中国建设银行杭州华星支行
账号：33050161678300000112
开票类型：电子普票、纸质专票`;
            }

            let oInput = document.createElement('textarea');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
.workbench {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.title_top {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    background: #fff;
    padding: 15px 0;
    span {
        color: #fd733c;
        margin: 0 8px 016px;
    }
}
.text_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    .content {
        width: 49%;
        margin-right: 1%;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 12px;
        position: relative;
        p:nth-child(2) {
            padding-top: 16px;
        }
        p:last-child {
            padding-bottom: 16px;
        }
        p {
            padding: 0 16px;
            font-size: 14px;
            color: #000;
            line-height: 26px;
            span {
                color: #666666;
                display: inline-block;
                width: 70px;
            }
        }
        .index {
            position: absolute;
            right: 16px;
            top: 6px;
            display: flex;
            align-items: center;
            button {
                font-size: 20px;
                margin-right: 6px;
            }
            span:nth-child(2) {
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 24px;
                text-align: center;
                line-height: 24px;
                font-size: 13px;
            }
        }
    }
    .content:nth-child(1) {
        span:nth-child(2) {
            background: #fae192;
            color: #d28d2d;
        }
    }
    .content:nth-child(2) {
        .span:nth-child(2) {
            background: #e0f7ff;
            color: #3073a9;
        }
    }
    .content:nth-child(3) {
        span:nth-child(2) {
            background: #efdbcd;
            color: #b98b68;
        }
    }
    .content:nth-child(4) {
        span:nth-child(2) {
            background: #edf1f3;
            color: #6577a2;
        }
    }
    .content:nth-child(5) {
        span:nth-child(2) {
            background: #e4efcd;
            color: #68b97d;
        }
    }
    .content:nth-child(6) {
        span:nth-child(2) {
            background: #f0edf3;
            color: #8872a8;
        }
    }
    .content:nth-child(7) {
        span:nth-child(2) {
            background: #efcde4;
            color: #b9687a;
        }
    }
    .content:nth-child(8) {
        span:nth-child(2) {
            background: #f3edee;
            color: #a87278;
        }
    }
    .content:nth-child(9) {
        span:nth-child(2) {
            background: #f3edee;
            color: #a87278;
        }
    }
}
</style>
